<template>
    <el-form v-if="validToken && !confirmed" :model="resetPassForm" :rules="validationRules" ref="resetPassForm" class="login-page__form w-full m-auto">
        <el-form-item prop="password">
            <el-input class="bg-white" v-model="resetPassForm.password" placeholder="New password" autocomplete="off" show-password></el-input>
        </el-form-item>
        <el-form-item prop="checkPassword">
            <el-input class="bg-white" v-model="resetPassForm.checkPassword" placeholder="Confirm password" autocomplete="off" show-password></el-input>
        </el-form-item>
        <el-form-item>
            <el-button class="bg-primary-light text-white text-bold rounded-md w-full p-5" type="submit" value="submit" @click.prevent="resetSubmit">
                SUBMIT
            </el-button>
        </el-form-item>
        <div class="flex flex-row justify-center">
            <a class="text-sm text-primary-light font-bold hover:text-primary" href="#" @click.prevent="returnLoginForm">Back to Log in</a>
        </div>
    </el-form>
    <div v-else-if="showInvalidToken">
        <h3 class="font-display py-6 text-center">Sorry, something went wrong</h3>
        <span class="inline-block align-middle text-sm pl-3">The password reset link is invalid, if this is in error, please try resetting your password again.</span>
    </div>
    <div v-else-if="!validToken"></div>
    <div class="flex items-center justify-center h-20 w-full" v-else>
        <Loader class="w-12 h-12" />
    </div>
</template>

<script>
import MessageDialog from '@/components/shared/mixins/messageDialog'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import Loader from '@/assets/loader.svg'

export default {
    name: 'resetPassForm',
    mixins: [MessageDialog, HttpMixin],
    props: {
        token: {
            type: String,
            required: true,
            default: ''
        },
        email: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            longDuration: 10000,
            validToken: false,
            confirmed: false,
            showInvalidToken: false,
            resetPassForm: {
                password: '',
                checkPassword: '',
            },
            // element ui validation rules
            validationRules: {
                password: [
                    { validator: this.validatePassword, trigger: 'blur' },
                ],
                checkPassword: [
                    { validator: this.validateCheckPassword, trigger: 'blur' },
                ],
            },
        }
    },
    mounted() {
        if (this.token) {
            this.checkToken()
        }
    },
    methods: {
        validatePassword(rule, value, callback) {
            if (value === '') {
                callback(new Error('Please enter a password'))
            } else {
                if (this.resetPassForm.checkPassword !== '') {
                    this.$refs.resetPassForm.validateField('checkPassword')
                }
                callback();
            }
        },
        validateCheckPassword(rule, value, callback) {
            if (value === '') {
                callback(new Error('Please enter the password again'))
            } else if (value !== this.resetPassForm.password) {
                callback(new Error(`Two passwords don't match!`))
            } else {
                callback();
            }
        },
        returnLoginForm() {
            this.$emit('return-login')
        },
        checkToken() {
            const httpOptions = this.getPostOrPatchOptions({token: this.token, email: this.email}, 'reset_password/validate_token')

            this.$http(httpOptions)
                .then(({ data: { status }}) => {
                    // TODO: do something on token check
                    console.log('check token response', status)
                    // if it's not found then something is wrong
                    if (status && status.toLowerCase() === 'ok') {
                        this.validToken = true
                    } else if (status && status.toLowerCase() === 'notfound') {
                        this.message = 'Invalid token, please try resetting your password again'
                        // this.type = 'error'
                        // this.showMessage()
                        this.validToken = false
                        this.showInvalidToken = true
                        this.confirmed = false
                    } else {
                        this.validToken = false
                        this.$emit('return-login')
                    }
                })
                .catch((err) => {
                    // TODO: do something on failed token
                    console.log('confirm error', err)

                    // this.message = 'This password reset link has expired, please try resetting your password again'
                    // this.type = 'error'
                    // this.showMessage({duration: this.longDuration})
                    //if it's a 404, the token has been used
                    this.message = 'Invalid token, please try resetting your password again'
                    // this.type = 'error'
                    // this.showMessage()
                    this.validToken = false
                    this.showInvalidToken = true
                    this.confirmed = false
                })
        },
        confirmReset() {
            const data = {
                token: this.token,
                password: this.resetPassForm.password
            }
            const httpOptions = this.getPostOrPatchOptions(data, 'reset_password/confirm')

            return this.$http(httpOptions)
        },
        resetSubmit() {
            console.log('resetPassForm', this.$refs.resetPassForm)
            this.$refs['resetPassForm'].validate(valid => {
                if (valid) {
                    console.log('resetPassForm IS valid:', valid)

                    this.confirmReset()
                        .then((response) => {
                            console.log('response from rest', response)
                            this.message = 'Successfully updated password'
                            this.type = 'success'
                            this.showMessage({duration: this.longDuration})
                            this.returnLoginForm()
                        })
                        .catch((err) => {
                            console.log('confirm error', err)
                            this.message = 'Error sending password reset'
                            this.type = 'error'
                            this.showMessage({duration: this.longDuration})
                        })
                } else {
                    console.error('invalid form:', valid)
                    return false
                }
            })
        },
        // async recaptcha() {
        //     // (optional) Wait until recaptcha has been loaded.
        //     await this.$recaptchaLoaded()
        //
        //     // Execute reCAPTCHA with action "login".
        //     const token = await this.$recaptcha('login')
        //     // now need to check the token is ok
        //
        //     // Do stuff with the received token.
        // }
    },
    watch: {
        token() {
            if (this.token) {
                this.checkToken()
            }
        }
    }
}
</script>

<style scoped>

</style>
