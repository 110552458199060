import axios from 'axios'

class UserService {
    
    async getCurrentUser() {
        const url = 'user/current/'
        try {
            const { data: userData } = await axios.get(url)
            if (userData) userData.isNewUser = userData?.newUser
            return userData || {}
        } catch (e) {
            console.error(e)
            return {}
        }
    }
}

export const userService = new UserService()
