<template>
    <div class="login-page flex flex-col h-full w-full">
        <div class="grid lg:grid-cols-6 xl:grid-cols-4 h-full">
            <div
                class="flex flex-col items-center justify-center col-span-4 lg:col-span-2 xl:col-span-1 h-full px-8"
            >
                <div class="flex flex-col justify-center items-center w-full">
                    <img
                        src="@/assets/connect_logo2.png"
                        class="login-page__logo p-0 m-auto mb-2 lg:mb-10"
                        alt="connect logo"
                    />
                    <div class="flex flex-col items-center w-full">
                        <div class="m-0 lg:my-10">
                            <p
                                class="w-full lg:w-48 text-md text-left font-display text-black"
                            >
                                Don't have an account?
                                <a
                                    href="#"
                                    class="text-md font-display text-primary-light font-bold text-left hover:text-primary"
                                    @click.prevent="loginClickHandler"
                                    >{{ registerLoginText }}</a
                                >
                            </p>
                        </div>
                        <div
                            :style="rainbowLine"
                            class="w-full h-1 mb-0 mr-12"
                        ></div>
                    </div>
                    <!-- spacer div -->
                    <div class="h-16 my-16 hidden lg:block"></div>
                </div>
            </div>
            <div
                class="flex flex-col items-center justify-center col-span-4 lg:col-span-4 xl:col-span-3 h-full bg-grey-7"
            >
                <div
                    class="login-page__modal flex flex-col items-center justify-start w-full h-full m-auto"
                    v-bind:class="{ signup: loginModal == 'register' }"
                >
                    <div class="flex items-center h-full w-full rounded-lg">
                        <div class="w-full self-center bg-white p-4">
                            <h1 class="mb-3 text-center text-xl lg:text-4xl">
                                {{ registerLoginTitle }}
                            </h1>

                            <!-- Log in form -->
                            <LoginForm
                                v-if="loginModal == 'login'"
                                @forgot-pass="forgotPass"
                                :auth-ready="authReady"
                                :invitation-token="invitationToken"
                            />

                            <!-- Register form -->
                            <RegisterForm
                                v-else-if="loginModal == 'register'"
                                :invitation-token="invitationToken"
                            />

                            <!-- forgot password -->
                            <ForgotPassForm
                                @return-login="returnLoginForm"
                                v-else-if="loginModal == 'forgot'"
                            />

                            <!-- forgot password -->
                            <ResetPassForm
                                :token="token"
                                :email="email"
                                @return-login="returnLoginForm"
                                v-else
                            />
                        </div>
                    </div>
                </div>
                <GlobalFooter class="w-full px-4 m-0" />
            </div>
        </div>
    </div>
</template>

<script>
import GlobalFooter from "@/components/shared/GlobalFooter";
import LoginForm from "@/components/login/partials/loginForm";
import RegisterForm from "@/components/login/partials/registerForm";
import ForgotPassForm from "@/components/login/partials/forgotPassForm";
import rainbowSvg from "@/assets/rainbowline.svg";
import ResetPassForm from "../components/login/partials/resetPassForm";

export default {
    name: "LoginRegister",
    props: {
        invitationToken: {
            type: String,
            default: null,
        },
    },
    components: {
        ResetPassForm,
        GlobalFooter,
        LoginForm,
        RegisterForm,
        ForgotPassForm,
    },
    data() {
        return {
            isDev: process.env.NODE_ENV === "development",
            loginModal: "login",
            authReady: false,
        };
    },
    mounted() {
        this.onReady()
            .then((value) => {
                this.authReady = value;
                if (this.token) {
                    this.resetPass();
                }
            })
            .catch((err) => {
                console.error("login auth error", err);
            });

        if (this.$route.path.toLowerCase().startsWith("/signup")) {
            this.loginModal = "register";
        }
    },
    methods: {
        onReady() {
            return new Promise((resolve, reject) => {
                let auth = this.$auth.ready();
                if (auth) {
                    resolve(auth);
                } else {
                    reject(auth);
                }
            });
        },
        forgotPass() {
            this.loginModal = "forgot";
        },
        returnLoginForm() {
            this.loginModal = "login";
        },
        resetPass() {
            this.loginModal = "reset";
        },
        loginClickHandler() {
            let loginState;
            switch (this.loginModal) {
                case "login":
                    loginState = "register";
                    break;
                case "register":
                    loginState = "login";
                    break;
                case "forgot":
                    loginState = "login";
                    break;
                case "reset":
                    loginState = "login";
                    break;
                default:
                    loginState = "login";
            }
            this.loginModal = loginState;
        },
        submitLoginForm(e) {
            console.log("submitLoginForm", e);
        },
        loginSubmit() {
            let validItems = [];
            this.$refs["loginForm"].validate((valid) => {
                if (valid) {
                    console.log("loginSubmit IS valid:", valid);
                    validItems.push[valid];
                } else {
                    console.log("loginSubmit not valid:", valid);
                    return false;
                }
            });
        },
        forgotSubmit() {
            console.log("forgotSubmit");
        },
        submitRegisterForm(e) {
            console.log("submitRegisterForm", e);
        },
        registerSubmit() {
            console.log("registerSubmit");
        },
        termsConditionsHandler(e) {
            console.log("termsConditionsHandler", e);
        },
        forgotPassHandler() {
            this.loginModal = "forgot";
        },
    },
    computed: {
        registerLoginText() {
            let text;
            switch (this.loginModal) {
                case "login":
                    text = "Sign up";
                    break;
                case "register":
                    text = "Log in";
                    break;
                case "forgot":
                    text = "Log in";
                    break;
                case "reset":
                    text = "Log in";
                    break;
                default:
                    text = "Log in";
            }
            return text;
        },
        registerLoginTitle() {
            let text;
            switch (this.loginModal) {
                case "login":
                    text = "Log in";
                    break;
                case "register":
                    text = "Sign up";
                    break;
                case "forgot":
                    text = "Forgot Password";
                    break;
                case "reset":
                    text = "Reset Password";
                    break;
                default:
                    text = "Log in";
            }
            return text;
        },
        rainbowLine() {
            return {
                backgroundImage: `url('${rainbowSvg}')`,
            };
        },
        token() {
            return this.$route.query.token;
        },
        email() {
            return this.$route.query.email;
        },
    },
};
</script>
