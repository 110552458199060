export default {
    methods: {
        async recaptcha(action) {
            const isLoaded = await this.$recaptchaLoaded()
    
            // Execute reCAPTCHA with action "login".
            if (isLoaded) {
                this.token = await this.$recaptcha(action)
            }
        },
        validate(params) {
            if (!this.getPostOrPatchOptions) throw Error('required the httpMixin')
            
            return new Promise((resolve, reject) => {
                const httpOptions = this.getPostOrPatchOptions(params, 'recaptcha.validate')
                this.$http(httpOptions)
                    .then((response) => {
                        if (response.status !== 201) {
                            reject('Something went wrong')
                        } else {
                            resolve(true)
                        }
                    })
                    .catch(error => {
                        console.log('error from recaptcha', error)
                    })
            })
        }
    }
}
