<template>
    <el-form
        :model="registerForm"
        :rules="validationRules"
        ref="registerForm"
        class="login-page__form w-full m-auto register-form"
    >
        <el-form-item prop="email">
                <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Email</label>
            <el-input class="bg-white" v-model="registerForm.email" placeholder="Email"></el-input>
        </el-form-item>
        <el-form-item prop="password">
                <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Password</label>
            <el-input class="bg-white" v-model="registerForm.password" placeholder="Password" type="password" show-password></el-input>
        </el-form-item>

        <UserProfile v-model="registerForm" ref="userProfileForm" />
        <div class="grid grid-cols-1 gap-0 mb-0 w-full">
            <el-form-item prop="subscribed" class="col-span-1 mb-0">
                <el-checkbox v-model="registerForm.subscribed">Subscribe to our newsletter</el-checkbox>
            </el-form-item>
            <Declaration class="col-span-1 mb-0" v-model="registerForm" ref="declarationForm" tnc-text="Terms and conditions of Use" :pdf="tncPdf" />
        </div>
        <el-form-item class="mb-0">
            <el-button
                class="bg-primary-light text-white text-bold rounded-md p-5 relative float-right"
                type="primary"
                :disabled="addingUser"
                @click.prevent="registerSubmit"
            >
                SIGN UP
            </el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import Declaration from '@/components/partials/Declaration'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import { mapState } from 'vuex'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import RecaptchaFns from '../../shared/mixins/recaptchaFns'
import UserProfile from '../../shared/UserProfile'
import { AuthMixin } from "@/components/shared/mixins/authHelpers";

export default {
    name: 'RegisterForm',
    props: {
        invitationToken: {
            type: String,
            default: null
        }
    },
    components: { UserProfile, Declaration },
    mixins: [ HttpMixin, MessageDialog, RecaptchaFns, AuthMixin ],
    data() {
        return {
            token: '',
            registerForm: {
                email: '',
                password: '',
                title: '',
                firstName: '',
                lastName: '',
                position: '',
                phone: {
                    countryCode: 'AU',
                    phone: '',
                    archived: false
                },
                subscribed: false,
                tncSigned: false
            },
            // element ui validation rules
            validationRules: {
                email: [
                    { required: true, message: 'Field can not be empty', trigger: 'blur' },
                    { type: 'email', message: 'Please use a valid email', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: 'Please enter a secure password', trigger: 'blur' },
                    { min: 5, message: 'Password is too short, please enter more than 5 characters', trigger: 'blur' }
                ],
                termsConditions: [{ required: true, message: 'You must agree to the terms and conditions', trigger: 'blur' }]
            },
            tncPdf: require('@/assets/QPA_Connect_Users_Terms_and_Conditions_MS.pdf'),
            addingUser: false
        }
    },
    created() {
        if (this.invitationToken) {
            this.$http({
                method: 'GET',
                url: `web.accountinvitation/${this.invitationToken}/`
            }).then(({ data }) => {
                const invitation = data.invitation ? camelcaseKeys(data.invitation, {deep: true}) : null
                if (invitation) {
                    this.registerForm.email = invitation.email
                    this.registerForm.firstName = invitation.firstName
                    this.registerForm.lastName = invitation.lastName
                }
            }).catch(err => {
            })
        }
    },
    async mounted() {
        this.$store.dispatch('fetchStaticChoices')

        await this.$recaptchaLoaded()
        // show reCAPTCHA badge:
        this.$recaptchaInstance.showBadge()
    },
    methods: {
        async registerSubmit(e) {
            this.addingUser = true
            let returnValue = false
            const formsToValidate = [
                this.$refs['userProfileForm'].validate(),
                this.$refs['declarationForm'].validate(),
                this.$refs['registerForm'].validate(),
            ]
            let resolved
            try{
                resolved = await Promise.all(formsToValidate)
            } catch(e) {
                // fail fast at this point
                this.addingUser = false
                return returnValue
            }

            const isValid = resolved && resolved.length === formsToValidate.length ? resolved.indexOf(false) === -1 : false
            if (isValid) {
                // first we go get the token:
                let response
                try{
                    response = await this.recaptcha('signup')
                } catch(e) {
                    this.message = 'Something went wrong, please try again later or contact your systems administrator'
                    this.type = 'error'
                    this.addingUser = false
                    this.showMessage({duration: 5000})
                }
                if (this.token) {
                    // now we check the token
                    try {
                        response = await this.validate({recaptcha: this.token})
                    } catch(e) {
                        this.message = 'Could not validate your request, please try again later or contact your systems administrator'
                        this.type = 'error'
                        this.addingUser = false
                        this.showMessage({duration: 5000})
                    }

                    // if it succeeded then we go and register, otherwise we try again
                    this.signup()
                    returnValue = true
                }
            } else {
                console.error('invalid form:', resolved)
                this.addingUser = false
            }
            return returnValue

        },
        termsConditionsHandler() {
            console.log('termsConditionsHandler')
        },
        async testRecaptcha() {
            await this.recaptcha('signup')
            if (this.token) {
                // now we check the token
                this.validate({recaptcha: this.token})
                    .then(() => {
                        // if it succeeded then we go and register, otherwise we try again
                        // this.signup()
                        console.log('response from reqcaptcha')
                    })
                    .catch((err) => {
                        this.message = 'Could not validate your request, please try again later or contact your systems administrator'
                        this.type = 'error'
                        this.showMessage({duration: 5000})
                    })
            }
        },
        signup() {
            const email = this.registerForm.email.toLocaleLowerCase()
            let signupData = {
                email: email,
                password: this.registerForm.password,
                title: this.registerForm.title,
                firstName: this.registerForm.firstName,
                lastName: this.registerForm.lastName,
                position: this.registerForm.position,
                subscribed: this.registerForm.subscribed,
                tncSigned: this.registerForm.tncSigned
            }
            if (this.invitationToken) {
                signupData.invitation_token = this.invitationToken
            }
            const data = snakecaseKeys(signupData, {deep: true})

            // we only add the phone number if it exists
            if (this.registerForm.phone && this.registerForm.phone.phone !== '') {
                data['phone'] = this.registerForm.phone
            }
            const httpOptions = this.getPostOrPatchOptions(data, 'web.user')

            this.$http(httpOptions)
                .then(({ data }) => {
                    data = camelcaseKeys(data, {deep: true})
                    this.message = 'Successfully signed up, redirecting you to your practice area'
                    this.type = 'success'

                    const hasInvitation = data.hasInvitation
                    const invitationToken = this.invitationToken
                    setTimeout(() => {
                        this.$auth.login({
                            fetchUser: false,
                            data: {email: email, password: this.registerForm.password},
                            staySignedIn: true
                        })
                        .then(({ data }) => {

                            if (hasInvitation && !this.isStaff) {
                                this.$store.commit('SET_ROUTE_TO', {})
                                if (this.isSurveyor) {
                                    this.$router.push({name: 'account-settings', params: {id: this.$auth.user().userId}})
                                } else {
                                    this.$router.push(`/Practice/Invite/${invitationToken}`)
                                }
                            } else if (this.$auth.user()['singlePractice']) {
                                this.$router.push({name: 'practice-overview', params: { id: this.$auth.user().practiceId }})
                            } else {
                                this.$router.push({name: 'dashboard'})
                            }
                        })
                        .catch((err) => {
                            console.log('error logging in: ', err)
                        })
                        .finally(() => {
                            this.addingUser = false
                        })
                    }, 3000)

                })
                .catch((err) => {
                    console.log('%cError: could not create user', 'color: red;', err)
                    this.message = 'Sorry, signup failed due to a server error or the email already exists'
                    this.type = 'error'
                    this.addingUser = false
                })
                .finally(() => {
                    this.showMessage({duration: 5000})
                })
        }
    },
    async beforeDestroy() {
        this.resetMessage()
        await this.$recaptchaLoaded()
        // hide reCAPTCHA badge:
        this.$recaptchaInstance.hideBadge()
    }
}
</script>

<style></style>
