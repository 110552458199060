<template>
    <el-form
        :model="loginForm"
        :rules="validationRules"
        ref="loginForm"
        class="login-page__form w-full m-auto"
        @submit.prevent.native="loginSubmit"
    >
        <el-form-item prop="email">
            <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Email</label>
            <el-input
                data-cy="loginEmail"
                class="bg-white"
                v-model="loginForm.email"
                type="email"
                placeholder="Email"
                @keydown.enter.native="loginSubmit"
            ></el-input>
        </el-form-item>
        <el-form-item prop="pass">
            <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Password</label>
            <el-input
                data-cy="loginPassword"
                class="bg-white"
                v-model="loginForm.pass"
                type="password"
                placeholder="Password"
                show-password
                @keydown.enter.native="loginSubmit"
            ></el-input>
        </el-form-item>
        <el-form-item>
            <el-button
                data-cy="loginSubmit"
                class="bg-primary-light text-white text-bold rounded-md w-full p-5 uppercase"
                native-type="submit"
                :disabled="loading"
            >
                <fa-icon v-if="loading" icon="spinner" spin class="mr-2" />
                {{ btnText }}
            </el-button>
        </el-form-item>
        <div class="flex flex-row justify-center">
            <a class="text-sm text-primary-light font-bold hover:text-primary" href="#" @click.prevent="forgotPassHandler">Forgot your password?</a>
        </div>
    </el-form>
</template>

<script>
import schema from 'async-validator'
import { mapState } from 'vuex'
import { mapActions } from 'pinia'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import camelcaseKeys from 'camelcase-keys'
import { AuthMixin } from "@/components/shared/mixins/authHelpers"
import { onLogin } from '@/vue-apollo'
import { userService } from '@/services/user.service'
import { useAssessmentStore } from '@/stores/useAssessmentStore'

export default {
    name: 'LoginForm',
    props: {
        authReady: {
            type: Boolean,
            default: null
        },
        invitationToken: {
            type: String,
            default: null
        }
    },
    mixins: [MessageDialog, HttpMixin, BackendHelpers, AuthMixin],
    data() {
        return {
            message: '',
            type: '',
            loginForm: {
                email: '',
                pass: ''
            },
            // element ui validation rules
            validationRules: {
                email: [
                    { required: true, message: 'Field can not be empty', trigger: 'blur' },
                    { type: 'email', message: 'Please use a valid email', trigger: 'blur' },
                ],
                pass: [
                    { min: 8, max: 16, message: 'Please enter 8 - 16 characters', trigger: 'blur' },
                    { required: true, message: 'Please enter a secure password', trigger: 'blur' },
                ]
            },
            validator: null,
            loading: false
        }
    },
    mounted() {
        this.validator = new schema({
            // check if email is in db
        })
    },
    methods: {
        ...mapActions(useAssessmentStore, ['updateUser']),
        forgotPassHandler() {
            this.$emit('forgot-pass')
        },
        submitLoginForm() {
            console.log('submitLoginForm')
        },
        loginSubmit() {
            this.resetMessage()
            // let loginObj = {email: this.loginForm.email, password: this.loginForm.pass}
            // console.log('loginObj', loginObj)
            this.loading = true
            this.$refs['loginForm'].validate(valid => {
                if (valid) {
                    let loginData = {email: this.loginForm.email, password: this.loginForm.pass}

                    const invitationToken = this.invitationToken
                    if (invitationToken) {
                        loginData.invitation_token = invitationToken
                    }
                    this.$auth.login({
                        fetchUser: false,
                        data: loginData,
                        staySignedIn: true
                    })
                    .then(async ({ data }) => {
                        await onLogin(this.$apolloProvider.defaultClient)
                        const user = await userService.getCurrentUser()
                        this.$auth.user({ ...user })
                        this.updateUser(user)

                        data = camelcaseKeys(data, {deep: true})

                        if (data.hasInvitation && !this.isStaff) {
                            this.$store.commit('SET_ROUTE_TO', {})
                            if (this.isSurveyor) {
                                this.$router.push({name: 'account-settings', params: {id: this.$auth.user().userId}})
                            } else {
                                this.$router.push(`/Practice/Invite/${invitationToken}`)
                            }
                        } else if (this.routeTo && Object.keys(this.routeTo).length > 0 && this.routeTo.name != null) {
                            const routeTo = {...this.routeTo}
                            this.$store.commit('SET_ROUTE_TO', {})
                            this.$router.push(routeTo)
                        } else if (this.routeTo && typeof this.routeTo === 'string' && this.routeTo.indexOf(process.env.VUE_APP_DJANGO_BASE_URL) > -1) {
                            const routeTo = this.routeTo
                            this.$store.commit('SET_ROUTE_TO', {})
                            location.href = routeTo
                        } else {
                            if (this.$auth.user()['singlePractice']) {
                                if (this.$auth.user()['paymentPending']) {
                                    const httpOptions = this.getPostOrPatchOptions({}, `web.practice/${this.$auth.user().practiceId}`, true)

                                    try {
                                        const response = await this.$http(httpOptions)
                                        const practice = camelcaseKeys(response.data, {deep: true})
                                        this.goToBackendUrl(practice.applicationPaymentUrl)
                                    } catch(e) {
                                        // TODO: do something if the get doesn't work
                                    }
                                } else {
                                    this.$router.push({ name: 'dashboard' })
                                }
                            } else {
                                this.$router.push({ name: 'dashboard' })
                            }
                        }
                    })
                    .catch((err) => {
                        const res = err && err.response ? err.response.data : null
                        this.message = res && res.code ? res.detail : 'Error logging in'
                        this.type = 'error'
                        this.showMessage()
                        this.loading = false
                    })
                } else {
                    console.error('invalid form:', valid)
                    this.loading = false
                    return false
                }
            })
        }
    },
    computed: {
        ...mapState({
            routeTo: state => state.app.routeTo
        }),
        btnText() {
            return this.loading ? 'Please wait...' : 'login'
        }
    },
    watch: {
        email() {
            // commit email to store
            this.$store.commit('UPDATE_LOGIN_EMAIL', this.email)
        },
        authReady() {
            console.log('watch LOGINFORM $authReady', this.authReady)
        },
    },
    beforeDestroy() {
        this.resetMessage()
    }
}
</script>

<style></style>
