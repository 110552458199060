<template>
    <el-form :model="forgotPassForm" :rules="validationRules" ref="forgotPassForm" class="login-page__form w-full m-auto">
        <el-form-item prop="email">
            <el-input class="bg-white" v-model="forgotPassForm.email" placeholder="Your registered email"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button class="bg-primary-light text-white text-bold rounded-md w-full p-5" type="submit" value="submit" @click.prevent="forgotSubmit">
                SUBMIT
            </el-button>
        </el-form-item>
        <div class="flex flex-row justify-center">
            <a class="text-sm text-primary-light font-bold hover:text-primary" href="#" @click.prevent="returnLoginForm">Back to Log in</a>
        </div>
    </el-form>
</template>

<script>
import HttpMixin from '@/components/shared/mixins/httpMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'

export default {
    name: 'ForgotPassForm',
    mixins: [ HttpMixin, MessageDialog ],
    data() {
        return {
            forgotPassForm: {
                email: ''
            },
            // element ui validation rules
            validationRules: {
                email: [
                    { required: true, message: 'Field can not be empty', trigger: 'blur' },
                    { type: 'email', message: 'Please use a valid email', trigger: 'blur' }
                ]
            }
        }
    },
    methods: {
        returnLoginForm() {
            this.$emit('return-login')
        },
        forgotSubmit() {
            this.$refs['forgotPassForm'].validate(valid => {
                if (valid) {
                    const httpOptions = this.getPostOrPatchOptions({email: this.forgotPassForm.email}, 'reset_password')

                    this.$http(httpOptions)
                        .then(() => {
                            this.message = 'Password reset sent! Please check your email to continue'
                            this.type = 'success'
                            this.showMessage({duration: 5000})
                        })
                        .catch((err) => {
                            console.log('confirm error', err)
                            this.message = 'Error sending reset password to that email'
                            this.type = 'error'
                            this.showMessage({duration: 5000})
                        })
                } else {
                    console.error('invalid form:', valid)
                    return false
                }
            })
        },
    }
}
</script>

<style></style>
