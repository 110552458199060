<template>
    <el-form
        :model="userProfileForm"
        :rules="validationRules"
        ref="userProfileForm"
    >
        <div class="grid grid-cols-10 gap-2 mb-0 w-full">
            <el-form-item prop="title" class="col-span-2">
                <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Title</label>
                <el-select v-model="userProfileForm.title" placeholder="Title">
                    <el-option
                        v-for="(title, index) in titles"
                        :key="`title-${index}`"
                        :label="title.label"
                        :value="title.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="firstName" class="col-span-4">
                <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">First name</label>
                <el-input v-model="userProfileForm.firstName" placeholder="First name"></el-input>
            </el-form-item>
            <el-form-item prop="lastName" class="col-span-4">
                <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Last name</label>
                <el-input v-model="userProfileForm.lastName" placeholder="Last name"></el-input>
            </el-form-item>
        </div>
        <div class="grid grid-cols-10 gap-2 mb-0 w-full">
            <el-form-item prop="position" class="col-span-5">
                <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Position</label>
                <el-input v-model="userProfileForm.position" placeholder="Position"></el-input>
            </el-form-item>
            <el-form-item prop="phone" class="col-span-5">
                <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Phone</label>
                <PhoneNumber
                    name="phone"
                    v-model="userProfileForm.phone"
                    ref="phone"
                    :required="true"
                    :validPhone="true"
                />
            </el-form-item>
        </div>
        <div class="grid grid-cols-10 gap-2 mb-0 w-full">
            <el-form-item prop="phone_consent" class="col-span-5">
                <el-checkbox
                    v-if="isSurveyor"
                    class="mb-2 a-check-all"
                    ref="hasConsentedToShowPhoneCheckbox"
                    label="I consent to my contact number being included in the Connect portal for accreditation visits."
                    v-model="userProfileForm.hasConsentedToShowPhone"
                ></el-checkbox>
            </el-form-item>
        </div>
    </el-form>
</template>

<script>
import PhoneNumber from '@/components/shared/PhoneNumber'
import { mapState } from 'vuex'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'

export default {
    name: "UserProfile",
    components: { PhoneNumber },
    mixins: [AuthMixin],
    props: {
        value: Object,
        default: () => {}
    },
    data() {
        return {
            validationRules: {
                title: [{ required: true, message: "Field required", trigger: 'change' }],
                firstName: [{ required: true, message: "Field required", trigger: 'blur' }],
                lastName: [{ required: true, message: "Field required", trigger: 'blur' }],
                position: [{ required: true, message: "Field required", trigger: 'blur' }],
                phone: [{ required: true, message: "Field required", trigger: 'blur' }],
            }
        }
    },
    methods: {
        validate() {
            return Promise.all([this.$refs.phone.validate(), this.$refs.userProfileForm.validate()])
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        titles() {
            return this.staticChoices['user.title'] || []
        },
        languages() {
            return this.staticChoices['user.languages'] || []
        },
        userProfileForm: {
            get() {
                if (this.value) return this.value
                return {}
            },
            set(userProfileForm) {
                this.$emit('input', userProfileForm)
            }
        }
    }
}
</script>
